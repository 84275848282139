import Storage from "@aws-amplify/storage";

const IMAGE_MIME_TYPE_MAP = {
  "image/png": "png",
  "image/jpeg": "jpg",
  "image/jpg": "jpg"
};

export const uploadImage = (file, directory, name) => {
  console.log("uploading: " + name);
  let date = Date.now();
  const ext = IMAGE_MIME_TYPE_MAP[file.type];
  const finalName = name + "-" + date + "." + ext;
  return new Promise((resolve, reject) => {
    Storage.put(directory + `/${finalName}`, file, {
      level: "public",
      contentType: file.type
    })
      .then(result => {
        resolve(result.key);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const uploadFile = (file, directory) => {
  return Storage.put(directory + `/${file.name}`, file, {
    level: "private",
    contentType: file.type
  })
    .then(result => {
      return result.key;
    })
    .catch(err => {
      console.log(err);
    });
};

export const srcToFile = (src, fileName, mimeType) => {
  return fetch(src)
    .then(function(res) {
      return res.arrayBuffer();
    })
    .then(function(buf) {
      return new File([buf], fileName, { type: mimeType });
    });
};

import React from "react";

export const Store = React.createContext();

const initialState = {
  cars: [],
  importedCars: [],
  car: {},
  isVisible: false,
  modalIsVisible: false
};

function reducer(state, action) {
  switch (action.type) {
    case "RESET_CAR":
      return { ...state, car: {} };
    case "FETCH_CARS":
      return { ...state, cars: action.payload };
    case "FETCH_IMPORTED_CARS":
      return { ...state, importedCars: action.payload };
    case "FETCH_SINGLE_CAR":
      return { ...state, car: action.payload };
    case "ADD_CAR":
      return { ...state, car: action.payload };
    case "ADD_IMPORTED_CAR":
      return { ...state, importedCar: action.payload };
    case "UPDATE_CAR":
      return { ...state, car: action.payload };
    case "UPDATE_IMPORTED_CAR":
      return { ...state, importedCar: action.payload };
    case "SET_SPINNER":
      return { ...state, isVisible: action.payload };
    case "SET_MODAL":
      return { ...state, modalIsVisible: action.payload };
    default:
      return state;
  }
}

export function StoreProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}

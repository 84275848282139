import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Button from "react-bootstrap/Button";
import { faMinus, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Storage from "@aws-amplify/storage";

const removeIcon = {
  color: "white",
  borderRadius: "0.2rem",
  padding: "0.3rem",
  position: "absolute",
  right: "-5px",
  top: "-5px",
  cursor: "pointer"
};

const showCursor = {
  cursor: "pointer"
};

export default function Basic(props) {
  const {
    files,
    setFiles,
    type,
    previewFiles,
    setPreviewFiles,
    id,
    removeFile
  } = props;
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: acceptedFiles => {
      handleDrop(acceptedFiles);
    }
  });

  const handleDrop = acceptedFiles => {
    setFiles([...files, ...acceptedFiles]);
    setPreviewFiles([
      ...previewFiles,
      ...acceptedFiles.map(file => {
        return { key: file.path, size: file.size, isDropped: true };
      })
    ]);
  };

  const removePreview = file => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
    const newPreviewFiles = [...previewFiles];
    newPreviewFiles.splice(newPreviewFiles.indexOf(file), 1);
    setPreviewFiles(newPreviewFiles);
  };

  const getFile = async key => {
    const dataFromStorage = await Storage.get(id + "/" + type + "/" + key, {
      level: "private"
    });

    window.open(dataFromStorage, "_blank");
  };

  const removeAll = () => {
    setFiles([]);
    setPreviewFiles([]);
  };

  const filesList = previewFiles.map(file => (
    <li key={file.key} style={{ position: "relative" }}>
      <a
        style={!file.isDropped ? showCursor : null}
        onClick={!file.isDropped ? () => getFile(file.key) : () => {}}
      >
        {file.key} - {file.size} bytes {file.isDropped && " *NIEUW*"}
      </a>
      <div
        onClick={
          !file.isDropped
            ? () => {
                removeFile(file, type);
                removePreview(file);
              }
            : () => removePreview(file)
        }
        style={removeIcon}
        className="bg-danger"
      >
        <FontAwesomeIcon icon={faMinus} />
      </div>
    </li>
  ));

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p className="text-center">
          Drag 'n' drop of klik hier om uw bestanden up te loaden.
        </p>
      </div>
      <aside>
        <h4>Bestanden:</h4>
        <ul>{filesList}</ul>
      </aside>
    </section>
  );
}

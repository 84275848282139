import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Auth } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";

import React from "react";

import logo from "../../assets/LogoGoldenCarsLang.svg";
import { API_URL } from "../../context/Actions";

const logout = () => {
  Auth.signOut()
    .then((data) => console.log(data))
    .catch((err) => console.log(err));
};

const handleDownload = async () => {
  const token = await Auth.currentSession();
  const response = await fetch(API_URL + "customers", {
    method: "GET",
    headers: {
      Accept: "text/csv",
      "Content-Type": "text/csv",
      Authorization: token.idToken.jwtToken,
    },
  });
  const blob = await response.blob();
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `klanten.csv`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export default () => {
  return (
    <Navbar
      bg="dark"
      expand="lg"
      variant="dark"
      style={{
        padding: "0.5% 8.33% 0.5% 8.33%",
      }}
    >
      <Navbar.Brand href="https://goldencarsbelgium.be">
        <img src={logo} alt="Logo" style={{ width: "18rem" }} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Item>
            <LinkContainer to="/cars-online">
              <Nav.Link>Aanbod online</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to="/cars-offline">
              <Nav.Link>Aanbod offline</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to="/import-online">
              <Nav.Link>Import online</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          <Nav.Item>
            <LinkContainer to="/import-offline">
              <Nav.Link>Import offline</Nav.Link>
            </LinkContainer>
          </Nav.Item>
        </Nav>
        <Button onClick={handleDownload}>Klantenbestand</Button>
        <NavDropdown title="Settings" id="basic-nav-dropdown">
          <NavDropdown.Item onClick={logout}>Log uit</NavDropdown.Item>
        </NavDropdown>
      </Navbar.Collapse>
    </Navbar>
  );
};

import React from "react";

export default props => {
  const overlayStyle = {
    backgroundColor: "rgba(189, 147, 9,0.7)",
    width: "100%",
    height: "20%",
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: 1,
    display: "flex",
    justifyContent: "center" /* align horizontal */,
    alignItems: "center",
    color: "white"
  };

  const { car } = props;

  return (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        {car.isSold && <div style={overlayStyle}>VERKOCHT</div>}
        <img
          src={
            "https://d1nlbhl3drwsqr.cloudfront.net/600x400/filters:upscale()/public/" +
            car.imagePaths
          }
          alt={car.brand}
          className="img-fluid w-100"
        />
      </div>
      <div className="row pt-1 ">
        <div className="col-12">
          <h4>
            {car.brand} {car.series}
          </h4>
        </div>
        <div className="col-12">
          <p>{car.infoPrice}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

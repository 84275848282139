import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, { useState, useEffect } from "react";
import { render, unmountComponentAtNode } from "react-dom";

const CustomModal = props => {
  const { handleAction, action } = props;

  const handleActionModal = () => {
    close();
    handleAction();
  };

  const close = () => {
    removeElement();
  };

  const removeElement = () => {
    const target = document.getElementById("react-confirm-alert");
    unmountComponentAtNode(target);
    target.parentNode.removeChild(target);
  };

  return (
    <Modal show={true} onHide={close} animation={true}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>{action}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Annuleer
        </Button>
        <Button variant="primary" onClick={() => handleActionModal()}>
          Bevestigen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default properties => {
  const divTarget = document.createElement("div");
  divTarget.id = "react-confirm-alert";
  document.body.appendChild(divTarget);
  render(<CustomModal {...properties} />, divTarget);
};

import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Navbar from "./components/core/Navbar";

import Cars from "./components/cars/Cars";

import { StoreProvider } from "./context/Store";
import HTML5Backend from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import CarDetails from "./components/car-details/CarDetails";
import { ImportedDetails } from "./components/imported/ImportedDetails";
import SpinnerWrapper from "./components/core/SpinnerWrapper";
import Imported from "./components/imported/Imported";

const App = props => {
  if (props.authState === "signedIn") {
    return (
      <DndProvider backend={HTML5Backend}>
        <Router>
          <Navbar />
          <StoreProvider>
            <SpinnerWrapper />

            <Redirect exact from="/" to="/cars-online" />
            <Route
              path="/cars-online"
              render={props => <Cars {...props} isOnline={true} />}
            />
            <Route
              path="/cars-offline"
              render={props => <Cars {...props} isOnline={false} />}
            />
            <Route
              path="/import-online"
              render={props => <Imported {...props} isOnline={true} />}
            />
            <Route
              path="/import-offline"
              render={props => <Imported {...props} isOnline={false} />}
            />
            <Route
              path="/car-create"
              render={props => <CarDetails {...props} />}
            />
            <Route
              path="/import-create"
              render={props => <ImportedDetails {...props} />}
            />
            <Route
              path="/import-details/:id"
              render={props => <ImportedDetails {...props} />}
            />
            <Route
              path="/car-details/:id"
              render={props => <CarDetails {...props} />}
            />
          </StoreProvider>
        </Router>
      </DndProvider>
    );
  } else {
    return null;
  }
};

export default App;

import React from "react";

import { Link } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import CarItem from "./CarItem";

import { fetchCarsAction, setSpinnerAction } from "../../context/Actions";

export default props => {
  const { state, dispatch, isOnline } = props;

  React.useEffect(() => {
    isOnline
      ? fetchCarsAction("", dispatch, true)
      : fetchCarsAction("", dispatch, false);
  }, []);

  return (
    <React.Fragment>
      <Col md={{ span: 10, offset: 1 }}>
        <Row>
          {state.cars.map(car => {
            return (
              <Col key={car._id} md="4" className="mt-3 mb-3">
                <Link
                  to={"car-details/" + car._id}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <CarItem car={car} />
                </Link>
              </Col>
            );
          })}
        </Row>
      </Col>
    </React.Fragment>
  );
};

import React from "react";
import { SignIn } from "aws-amplify-react";
import config from "./aws-exports";
import App from "./App";
import {
  Authenticator,
  RequireNewPassword,
  ForgotPassword
} from "aws-amplify-react/dist/Auth";
import Container from "react-bootstrap/Container";

class AppWithAuth extends React.Component {
  render() {
    return (
      <Container fluid={true} className="p-0">
        <Authenticator hideDefault={true} amplifyConfig={config}>
          <ForgotPassword />
          <SignIn />
          <RequireNewPassword />
          <App />
        </Authenticator>
      </Container>
    );
  }
}

export default AppWithAuth;

import React from "react";
import ReactCountryFlag from "react-country-flag";

export const ImportedItem = ({ car }) => {
  const overlayStyle = {
    backgroundColor: "rgba(189, 147, 9,0.7)",
    width: "100%",
    height: "20%",
    position: "absolute",
    bottom: 0,
    left: 0,
    zIndex: 1,
    display: "flex",
    justifyContent: "center" /* align horizontal */,
    alignItems: "center",
    color: "white"
  };

  return (
    <React.Fragment>
      <div style={{ position: "relative" }}>
        <div style={overlayStyle}>IMPORTED</div>
        <img
          src={
            "https://d1nlbhl3drwsqr.cloudfront.net/600x400/filters:upscale()/public/" +
            car.imagePaths[0]
          }
          alt={car.brand}
          className="img-fluid w-100"
        />
      </div>
      <div className="d-flex pt-1 ">
        <ReactCountryFlag
          key="countryCode"
          countryCode={car.country.countryCode}
          svg
          className="border-right align-items-stretch"
          style={{
            paddingRight: "0.5rem",
            marginRight: "0.5rem",
            fontSize: "2em",
            lineHeight: "2em"
          }}
        />
        <h4>
          {car.year} - {car.brand} {car.series}
        </h4>
      </div>
    </React.Fragment>
  );
};

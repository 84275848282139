// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:6d4ca04f-8b72-4718-aaa2-29ebff93fae9",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_iNTTaUmG8",
    "aws_user_pools_web_client_id": "3nts7t9ci6fn1s7uo0p9sg45vb",
    "oauth": {},
    "aws_content_delivery_bucket": "gcb-manager-react-20190817145020-hostingbucket-production",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "http://gcb-manager-react-20190817145020-hostingbucket-production.s3-website.eu-central-1.amazonaws.com",
    "aws_user_files_s3_bucket": "gcb-manager-react23494091f841470bab069528a246c2dd-production",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;

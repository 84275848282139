import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { useDrag, useDrop } from "react-dnd";
import ItemTypes from "./ItemTypes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAlt, faMinus } from "@fortawesome/free-solid-svg-icons";

const handleStyle = {
  cursor: "move"
};

const buttonStyle = {
  border: "1 px"
};

const TrumpInputs = ({
  id,
  idx,
  trumps,
  handleTrumpChange,
  moveTrump,
  findTrump,
  deleteTrump
}) => {
  const trumpId = `trump-${idx}`;

  const originalIndex = findTrump(id).index;
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.TRUMP, id, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const [, drop] = useDrop({
    accept: ItemTypes.TRUMP,
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findTrump(id);
        moveTrump(draggedId, overIndex);
      }
    }
  });
  const opacity = isDragging ? 0 : 1;
  return (
    <div ref={node => drag(drop(node))}>
      <Form.Group
        style={{ opacity }}
        controlId={trumpId}
        className="d-flex mb-0"
      >
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text ref={drag} style={handleStyle} id="basic-addon1">
              <FontAwesomeIcon icon={faArrowsAlt} />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            required
            type="text"
            placeholder="bv. GECONTROLEERD OP 80 CONTROLEPUNTEN"
            name={trumpId}
            value={trumps[idx].value}
            data-idx={idx}
            className="value"
            onChange={handleTrumpChange}
          />
          <InputGroup.Append>
            <Button
              style={buttonStyle}
              variant="outline-secondary"
              onClick={() => deleteTrump(idx)}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>
    </div>
  );
};

TrumpInputs.propTypes = {
  idx: PropTypes.number,
  trumps: PropTypes.array,
  handleTrumpChange: PropTypes.func
};

export default TrumpInputs;

import React from "react";
import { Store } from "../../context/Store";
import Spinner from "react-bootstrap/Spinner";

export default () => {
  const { state } = React.useContext(Store);

  return (
    state.isVisible && (
      <div
        style={{
          width: "100%",
          height: "100%",
          zIndex: 10,
          top: 0,
          left: 0,
          position: "fixed",
          backgroundColor: "rgba(0, 0, 0, 0.5)"
        }}
      >
        <Spinner
          animation="border"
          variant="warning"
          size="xl"
          style={{
            position: "fixed",
            top: "50%",
            left: "48%"
          }}
        />
      </div>
    )
  );
};

import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { fetchCarsAction } from "../../context/Actions";

export default props => {
  const { dispatch, isOnline } = props;

  return (
    <React.Fragment>
      <Row>
        <Col md={{ span: 10, offset: 1 }} className="p-0 d-md-flex d-none">
          <div
            onClick={() => fetchCarsAction("", dispatch, isOnline)}
            className="button dark flex-even"
          >
            ALLE
          </div>
          <div
            onClick={() => fetchCarsAction("audi", dispatch, isOnline)}
            className="button less-dark flex-even"
          >
            AUDI
          </div>
          <div
            onClick={() => fetchCarsAction("bmw", dispatch, isOnline)}
            className="button dark flex-even"
          >
            BMW
          </div>
          <div
            onClick={() => fetchCarsAction("mercedes", dispatch, isOnline)}
            className="button less-dark flex-even"
          >
            MERCEDES
          </div>
          <div
            onClick={() => fetchCarsAction("volkswagen", dispatch, isOnline)}
            className="button dark flex-even"
          >
            VOLKSWAGEN
          </div>
          <div
            onClick={() => fetchCarsAction("other", dispatch, isOnline)}
            className="button less-dark flex-even"
          >
            ANDERE
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

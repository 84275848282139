import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Emoji from "react-emoji-render";
import DropImages from "../shared/DropImages";
import { countries } from "../../constants/CountriesDutch";
import ReactCountryFlag from "react-country-flag";
import {
  Typeahead,
  Highlighter,
  Token,
  hintContainer
} from "react-bootstrap-typeahead";

import Button from "react-bootstrap/Button";
import Modal from "../core/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  addImportedCarAction,
  updateImportedCarAction,
  setSpinnerAction
} from "../../context/Actions";
import { uploadImage } from "../../services/uploadFiles";
import { Store } from "../../context/Store";
import { useEffect } from "react";

const _renderMenuItemChildren = (option, props, index) => {
  return [
    <ReactCountryFlag
      key="countryCode"
      countryCode={option.countryCode}
      svg
      style={{ marginRight: "1rem" }}
    />,
    <Highlighter key="label" search={props.text}>
      {option.label}
    </Highlighter>
  ];
};

const HintedFormControl = hintContainer(Form.Control);

const _renderInput = inputProps => {
  return <HintedFormControl required {...inputProps} />;
};

export const ImportedDetails = ({ match, history }) => {
  const [importedCar, setImportedCar] = useState({
    brand: "",
    series: "",
    isVisible: true,
    year: ""
  });

  const [pictures, setPictures] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [validated, setValidated] = useState(false);
  const { state, dispatch } = React.useContext(Store);

  const { id } = match.params;

  useEffect(() => {
    if (id) {
      const car = state.importedCars.find(car => car._id === id);
      if (car) {
        setImportedCar(car);
        setSelectedCountry([car.country]);
        getImages(car.imagePaths);
      }
    }
  }, []);

  const handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setImportedCar(values => ({ ...values, [name]: value }));
  };

  const handleSubmit = event => {
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    Modal({
      handleAction: () => submit(),
      action:
        "Bent u zeker dat u de wagen wilt " + (id ? "updaten?" : "aanmaken?")
    });
  };

  const submit = () => {
    setSpinnerAction(true, dispatch);
    const transformedCar = { ...importedCar, country: selectedCountry[0] };

    setValidated(true);

    let promises = [];
    let count = 1;
    let imagePaths = [];

    if (id) {
      pictures.forEach(picture => {
        if (picture instanceof File) {
          promises.push(
            uploadImage(
              picture,
              "importedCarImages",
              (
                transformedCar.brand +
                "-" +
                transformedCar.series +
                "-" +
                count
              ).replace(/\s+/g, "-")
            )
          );
          count++;
        } else {
          imagePaths.push(picture.imagePath);
        }
      });
    } else {
      pictures.forEach(picture => {
        promises.push(
          uploadImage(
            picture,
            "importedCarImages",
            (
              transformedCar.brand +
              "-" +
              transformedCar.series +
              "-" +
              count
            ).replace(/\s+/g, "-")
          )
        );
        count++;
      });
    }

    Promise.all(promises)
      .then(results => {
        results.map(result => imagePaths.push(result));
        transformedCar.imagePaths = imagePaths;
        if (id) {
          updateImportedCarAction(transformedCar, dispatch, history);
        } else {
          addImportedCarAction(transformedCar, dispatch, history);
        }
      })
      .catch(error => {
        console.log(error);
        setSpinnerAction(false, dispatch);
      });
  };

  const getImages = async imagePaths => {
    let images = [];
    imagePaths.map(async imagePath => {
      images.push({
        preview:
          "https://d1nlbhl3drwsqr.cloudfront.net/600x400/filters:upscale()/public/" +
          imagePath,
        imagePath
      });
    });
    setPictures(images);
  };

  return (
    <>
      <Col md={{ span: 10, offset: 1 }}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Row className="mt-5">
            <Col>
              <Form.Group controlId="formImportedCarBrand">
                <Form.Label>Merk</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="bv. Mercedes"
                  name="brand"
                  value={importedCar.brand}
                  onChange={event => handleChange(event)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formImportedCarSeries">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="bv. CLA200 coupé"
                  name="series"
                  value={importedCar.series}
                  onChange={event => handleChange(event)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formImportedCarYear">
                <Form.Label>Jaar</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="2020"
                  name="year"
                  value={importedCar.year}
                  onChange={event => handleChange(event)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formCountryDropdown">
                <Form.Label>Land van herkomst</Form.Label>
                <Typeahead
                  required
                  id="basic-typeahead-example"
                  onChange={setSelectedCountry}
                  selected={selectedCountry}
                  options={countries}
                  placeholder="Kies een land..."
                  renderInput={_renderInput}
                  renderMenuItemChildren={_renderMenuItemChildren}
                />
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Form.Group controlId="formIsVisible">
              <Form.Check
                type="checkbox"
                label="Zichtbaar"
                name="isVisible"
                checked={importedCar.isVisible}
                onChange={event => handleChange(event)}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row className="mt-5">
            <h2 class="mt-5 mb-5 d-block">AFBEELDINGEN</h2>
            <DropImages files={pictures} setFiles={setPictures}></DropImages>
          </Form.Row>
          <Button variant="dark" type="submit" className="mt-3 mb-3">
            {" "}
            <FontAwesomeIcon icon={faCheck} />{" "}
            {id ? "Update wagen" : "Voeg wagen toe"}
          </Button>
        </Form>
      </Col>
    </>
  );
};

import { Auth } from "aws-amplify";

//export const API_URL = "http://localhost:4200/api/";
export const API_URL = "https://goldencarsbelgium.be/api/";

export const fetchCarsAction = async (brand, dispatch, online) => {
  setSpinnerAction(true, dispatch);
  const token = await Auth.currentSession();
  const data = await fetch(
    API_URL + "cars/" + "?brand=" + brand + "&online=" + online,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token.idToken.jwtToken,
      },
    }
  );
  const dataJSON = await data.json();
  setSpinnerAction(false, dispatch);
  return dispatch({
    type: "FETCH_CARS",
    payload: dataJSON.cars,
  });
};

export const fetchImportedCarsAction = async (dispatch, online) => {
  setSpinnerAction(true, dispatch);
  const token = await Auth.currentSession();
  const data = await fetch(API_URL + "imported/" + "?online=" + online, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token.idToken.jwtToken,
    },
  });
  const dataJSON = await data.json();
  setSpinnerAction(false, dispatch);
  return dispatch({
    type: "FETCH_IMPORTED_CARS",
    payload: dataJSON.importedCars,
  });
};

export const setSpinnerAction = async (isVisible, dispatch) => {
  return dispatch({
    type: "SET_SPINNER",
    payload: isVisible,
  });
};

export const setModalAction = async (isVisible, dispatch) => {
  return dispatch({
    type: "SET_MODAL",
    payload: isVisible,
  });
};

export const resetCar = async (dispatch) => {
  return dispatch({
    type: "RESET_CAR",
  });
};

export const fetchSingleCarAction = async (id, dispatch) => {
  setSpinnerAction(true, dispatch);
  const token = await Auth.currentSession();
  const data = await fetch(API_URL + "cars/" + id, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token.idToken.jwtToken,
    },
  });
  const dataJSON = await data.json();
  setSpinnerAction(false, dispatch);
  return dispatch({
    type: "FETCH_SINGLE_CAR",
    payload: dataJSON.car,
  });
};

export const addCarAction = async (car, dispatch, history) => {
  setSpinnerAction(true, dispatch);
  const token = await Auth.currentSession();
  const data = await fetch(API_URL + "cars/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token.idToken.jwtToken,
    },
    body: JSON.stringify(car),
  });
  const dataJSON = await data.json();
  setSpinnerAction(false, dispatch);
  history.push("/cars-online");
  return dispatch({
    type: "ADD_CAR",
    payload: dataJSON,
  });
};

export const addImportedCarAction = async (importedCar, dispatch, history) => {
  setSpinnerAction(true, dispatch);
  const token = await Auth.currentSession();
  const data = await fetch(API_URL + "imported/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token.idToken.jwtToken,
    },
    body: JSON.stringify(importedCar),
  });
  const dataJSON = await data.json();
  setSpinnerAction(false, dispatch);
  history.push("/import-online");
  return dispatch({
    type: "ADD_IMPORTED_CAR",
    payload: dataJSON,
  });
};

export const updateCarAction = async (car, dispatch, history) => {
  setSpinnerAction(true, dispatch);
  const token = await Auth.currentSession();
  const data = await fetch(API_URL + "cars/" + car._id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token.idToken.jwtToken,
    },
    body: JSON.stringify(car),
  });
  const dataJSON = await data.json();
  setSpinnerAction(false, dispatch);
  history.push("/cars-online");
  return dispatch({
    type: "UPDATE_CAR",
    payload: dataJSON,
  });
};

export const updateImportedCarAction = async (
  importedCar,
  dispatch,
  history
) => {
  setSpinnerAction(true, dispatch);
  const token = await Auth.currentSession();
  const data = await fetch(API_URL + "imported/" + importedCar._id, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token.idToken.jwtToken,
    },
    body: JSON.stringify(importedCar),
  });
  const dataJSON = await data.json();
  setSpinnerAction(false, dispatch);
  history.push("/import-online");
  return dispatch({
    type: "UPDATE_IMPORTED_CAR",
    payload: dataJSON,
  });
};

import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { useDrag, useDrop } from "react-dnd";
import ItemTypes from "./ItemTypes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAlt, faMinus } from "@fortawesome/free-solid-svg-icons";

const handleStyle = {
  cursor: "move"
};

const buttonStyle = {
  border: "1 px"
};

const OptionInputs = ({
  id,
  idx,
  options,
  handleOptionChange,
  moveOption,
  findOption,
  deleteOption
}) => {
  const optionId = `option-${idx}`;

  const originalIndex = findOption(id).index;
  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: ItemTypes.OPTION, id, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const [, drop] = useDrop({
    accept: ItemTypes.OPTION,
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findOption(id);
        moveOption(draggedId, overIndex);
      }
    }
  });
  const opacity = isDragging ? 0 : 1;

  return (
    <div ref={node => drag(drop(node))}>
      <Form.Group
        style={{ opacity }}
        controlId={optionId}
        className="d-flex mb-0"
      >
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text ref={drag} style={handleStyle} id="basic-addon1">
              <FontAwesomeIcon icon={faArrowsAlt} />
            </InputGroup.Text>
            <InputGroup.Checkbox
              data-idx={idx}
              checked={options[idx].bold}
              aria-label="Checkbox for following text input"
              onChange={handleOptionChange}
            />
          </InputGroup.Prepend>
          <Form.Control
            required
            type="text"
            placeholder="bv. GECONTROLEERD OP 80 CONTROLEPUNTEN"
            name={optionId}
            value={options[idx].value}
            data-idx={idx}
            className="value"
            onChange={handleOptionChange}
          />
          <InputGroup.Append>
            <Button
              style={buttonStyle}
              variant="outline-secondary"
              onClick={() => deleteOption(idx)}
            >
              <FontAwesomeIcon icon={faMinus} />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form.Group>
    </div>
  );
};

OptionInputs.propTypes = {
  idx: PropTypes.number,
  options: PropTypes.array,
  handleOptionChange: PropTypes.func
};

export default OptionInputs;

import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { addCarAction } from "../../context/Actions";

import image from "../../assets/Banner.jpg";

const containerStyle = {
  minHeight: "20rem",
  position: "relative",
  overflow: "hidden"
};

const imageStyle = {
  mbackgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "100%",
  backgroundImage: "url(" + image + ")",
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  position: "absolute",
  top: 0,
  left: 0
};

const overlayStyle = {
  backgroundImage:
    "linear-gradient(to bottom, rgba(0, 0, 0, 0.568), rgb(0, 0, 0))",
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1
};

export default props => {
  const { id } = props;

  return (
    <React.Fragment>
      <div style={containerStyle}>
        <div style={overlayStyle} />
        <div style={imageStyle} />
        <div
          className="content offset-1 d-flex flex-column"
          style={{ position: "absolute", zIndex: 1, top: "10rem" }}
        >
          <h1 style={{ color: "white" }} className="mb-5">
            {id ? "Update wagen." : "Voeg een nieuwe wagen toe."}
          </h1>
        </div>
      </div>
    </React.Fragment>
  );
};

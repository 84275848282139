import React from "react";

import OptionInputs from "./OptionInputs";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default ({
  moveOption,
  findOption,
  deleteOption,
  options,
  handleOptionChange,
  addOption,
  drop
}) => {
  return (
    <React.Fragment>
      <div className="d-flex align-content-center">
        <Button variant="dark" onClick={addOption} className="mt-3 mb-3">
          {" "}
          <FontAwesomeIcon icon={faPlus} /> Voeg een optie toe
        </Button>
        <span className="ml-3 my-auto">Vink aan om in het vet te zetten</span>
      </div>
      <div ref={drop}>
        {options.map((val, idx) => (
          <React.Fragment key={options[idx].id}>
            <OptionInputs
              idx={idx}
              id={`${options[idx].id}`}
              options={options}
              handleOptionChange={handleOptionChange}
              moveOption={moveOption}
              findOption={findOption}
              deleteOption={deleteOption}
            />
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  );
};

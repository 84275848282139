import React from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

export default ({ car, handleChange }) => {
  return (
    <React.Fragment>
      <h2 className="mt-3">BESCHRIJVING</h2>
      <Form.Group controlId="formDescription" className="row">
        <Form.Control
          required
          as="textarea"
          rows="5"
          name="infoDescription"
          placeholder="Typ hier de beschrijving"
          value={car.infoDescription}
          onChange={event => handleChange(event)}
        />
      </Form.Group>
      <h2 className="mt-3">ALGEMENE INFO</h2>
      <Form.Group controlId="formInfoBuildYear" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Bouwjaar</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            placeholder="bv. 2017"
            name="infoBuildYear"
            value={car.infoBuildYear}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoMileage" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Kilometerstand</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            placeholder="bv. 9 175 km"
            name="infoMileage"
            value={car.infoMileage}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoPower" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Vermogen</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            placeholder="bv. 340 pk / 250 kW"
            name="infoPower"
            value={car.infoPower}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoBodyShape" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Carrosserievorm</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            placeholder="bv. SUV"
            name="infoBodyShape"
            value={car.infoBodyShape}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoDrive" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Aandrijving</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            placeholder="bv. voorwielaandrijving"
            name="infoDrive"
            value={car.infoDrive}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoTransmission" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Transmissie</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            type="text"
            required
            placeholder="bv. Automaat"
            name="infoTransmission"
            value={car.infoTransmission}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoColor" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Kleur</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            type="text"
            required
            placeholder="bv. Wit"
            name="infoColor"
            value={car.infoColor}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoInterior" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Interieur</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            type="text"
            required
            placeholder="bv. Vol leder (bruin)"
            name="infoInterior"
            value={car.infoInterior}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <h2>PRIJS</h2>
      <Form.Group controlId="formInfoPriceNew" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Nieuwprijs</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            type="text"
            required
            placeholder="bv. €64 365"
            name="infoPriceNew"
            value={car.infoPriceNew}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoPrice" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">ONZE PRIJS</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            className=""
            placeholder="bv. €42 950"
            name="infoPrice"
            value={car.infoPrice}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoAdvantage" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Uw voordeel</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            className=""
            placeholder="bv. (-33%) €21 415"
            name="infoAdvantage"
            value={car.infoAdvantage}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoBTWRegime" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">BTW regime</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            className=""
            placeholder="bv. BTW niet-aftrekbaar (Marge wagen)"
            name="infoBTWRegime"
            value={car.infoBTWRegime}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <h2>FISCAAL</h2>
      <Form.Group controlId="formInfoEntryIntoServiceDate" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">
            Datum 1ste inverkeersstelling
          </Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            className=""
            placeholder="bv. 18/06/2018"
            name="infoEntryIntoServiceDate"
            value={car.infoEntryIntoServiceDate}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoFuel" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Brandstof</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            className=""
            placeholder="bv. Diesel"
            name="infoFuel"
            value={car.infoFuel}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoCO2" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">CO²-uitstoot</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            className=""
            placeholder="bv. 114 g/km"
            name="infoCO2"
            value={car.infoCO2}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoEuronorm" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Euronorm</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            className=""
            placeholder="bv. 6"
            name="infoEuronorm"
            value={car.infoEuronorm}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoFiscalHP" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Fiscale pk</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            className=""
            placeholder="bv. 11 pk"
            name="infoFiscalHP"
            value={car.infoFiscalHP}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoEngineCapacity" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Cilinderinhoud</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            className=""
            placeholder="bv. 1968 cc"
            name="infoEngineCapacity"
            value={car.infoEngineCapacity}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formInfoCylinders" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Cilinders</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            className=""
            placeholder="bv. 4"
            name="infoCylinders"
            value={car.infoCylinders}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
    </React.Fragment>
  );
};

export const countries = [
  {
    label: "Afghanistan",
    countryCode: "AF"
  },
  {
    label: "Åland",
    countryCode: "AX"
  },
  {
    label: "Albanië",
    countryCode: "AL"
  },
  {
    label: "Algerije",
    countryCode: "DZ"
  },
  {
    label: "Amerikaanse Maagdeneilanden",
    countryCode: "VI"
  },
  {
    label: "Amerikaans-Samoa",
    countryCode: "AS"
  },
  {
    label: "Andorra",
    countryCode: "AD"
  },
  {
    label: "Angola",
    countryCode: "AO"
  },
  {
    label: "Anguilla",
    countryCode: "AI"
  },
  {
    label: "Antarctica",
    countryCode: "AQ"
  },
  {
    label: "Antigua en Barbuda",
    countryCode: "AG"
  },
  {
    label: "Argentinië",
    countryCode: "AR"
  },
  {
    label: "Armenië",
    countryCode: "AM"
  },
  {
    label: "Aruba",
    countryCode: "AW"
  },
  {
    label: "Australië",
    countryCode: "AU"
  },
  {
    label: "Azerbeidzjan",
    countryCode: "AZ"
  },
  {
    label: "Bahama's",
    countryCode: "BS"
  },
  {
    label: "Bahrein",
    countryCode: "BH"
  },
  {
    label: "Bangladesh",
    countryCode: "BD"
  },
  {
    label: "Barbados",
    countryCode: "BB"
  },
  {
    label: "België",
    countryCode: "BE"
  },
  {
    label: "Belize",
    countryCode: "BZ"
  },
  {
    label: "Benin",
    countryCode: "BJ"
  },
  {
    label: "Bermuda",
    countryCode: "BM"
  },
  {
    label: "Bhutan",
    countryCode: "BT"
  },
  {
    label: "Bolivia",
    countryCode: "BO"
  },
  {
    label: "Bonaire, Sint Eustatius en Saba",
    countryCode: "BQ"
  },
  {
    label: "Bosnië en Herzegovina",
    countryCode: "BA"
  },
  {
    label: "Botswana",
    countryCode: "BW"
  },
  {
    label: "Bouvet",
    countryCode: "BV"
  },
  {
    label: "Brazilië",
    countryCode: "BR"
  },
  {
    label: "Britse Maagdeneilanden",
    countryCode: "VG"
  },
  {
    label: "Brits Indische Oceaanterritorium",
    countryCode: "IO"
  },
  {
    label: "Brunei",
    countryCode: "BN"
  },
  {
    label: "Bulgarije",
    countryCode: "BG"
  },
  {
    label: "Burkina Faso",
    countryCode: "BF"
  },
  {
    label: "Burundi",
    countryCode: "BI"
  },
  {
    label: "Cambodja",
    countryCode: "KH"
  },
  {
    label: "Canada",
    countryCode: "CA"
  },
  {
    label: "Centraal-Afrikaanse Republiek",
    countryCode: "CF"
  },
  {
    label: "Chili",
    countryCode: "CL"
  },
  {
    label: "China",
    countryCode: "CN"
  },
  {
    label: "Christmaseiland",
    countryCode: "CX"
  },
  {
    label: "Cocoseilanden",
    countryCode: "CC"
  },
  {
    label: "Colombia",
    countryCode: "CO"
  },
  {
    label: "Comoren",
    countryCode: "KM"
  },
  {
    label: "Congo-Brazzaville",
    countryCode: "CG"
  },
  {
    label: "Congo-Kinshasa",
    countryCode: "CD"
  },
  {
    label: "Cookeilanden",
    countryCode: "CK"
  },
  {
    label: "Costa Rica",
    countryCode: "CR"
  },
  {
    label: "Cuba",
    countryCode: "CU"
  },
  {
    label: "Curaçao",
    countryCode: "CW"
  },
  {
    label: "Cyprus",
    countryCode: "CY"
  },
  {
    label: "Denemarken",
    countryCode: "DK"
  },
  {
    label: "Djibouti",
    countryCode: "DJ"
  },
  {
    label: "Dominica",
    countryCode: "DM"
  },
  {
    label: "Dominicaanse Republiek",
    countryCode: "DO"
  },
  {
    label: "Duitsland",
    countryCode: "DE"
  },
  {
    label: "Ecuador",
    countryCode: "EC"
  },
  {
    label: "Egypte",
    countryCode: "EG"
  },
  {
    label: "El Salvador",
    countryCode: "SV"
  },
  {
    label: "Equatoriaal-Guinea",
    countryCode: "GQ"
  },
  {
    label: "Eritrea",
    countryCode: "ER"
  },
  {
    label: "Estland",
    countryCode: "EE"
  },
  {
    label: "Ethiopië",
    countryCode: "ET"
  },
  {
    label: "Faeröer",
    countryCode: "FO"
  },
  {
    label: "Falklandeilanden",
    countryCode: "FK"
  },
  {
    label: "Fiji",
    countryCode: "FJ"
  },
  {
    label: "Filipijnen",
    countryCode: "PH"
  },
  {
    label: "Finland",
    countryCode: "FI"
  },
  {
    label: "Frankrijk",
    countryCode: "FR"
  },
  {
    label: "Franse Zuidelijke en Antarctische Gebiede",
    countryCode: "TF"
  },
  {
    label: "Frans-Guyana",
    countryCode: "GF"
  },
  {
    label: "Frans-Polynesië",
    countryCode: "PF"
  },
  {
    label: "Gabon",
    countryCode: "GA"
  },
  {
    label: "Gambia",
    countryCode: "GM"
  },
  {
    label: "Georgië",
    countryCode: "GE"
  },
  {
    label: "Ghana",
    countryCode: "GH"
  },
  {
    label: "Gibraltar",
    countryCode: "GI"
  },
  {
    label: "Grenada",
    countryCode: "GD"
  },
  {
    label: "Griekenland",
    countryCode: "GR"
  },
  {
    label: "Groenland",
    countryCode: "GL"
  },
  {
    label: "Guadeloupe",
    countryCode: "GP"
  },
  {
    label: "Guam",
    countryCode: "GU"
  },
  {
    label: "Guatemala",
    countryCode: "GT"
  },
  {
    label: "Guernsey",
    countryCode: "GG"
  },
  {
    label: "Guinee",
    countryCode: "GN"
  },
  {
    label: "Guinee-Bissau",
    countryCode: "GW"
  },
  {
    label: "Guyana",
    countryCode: "GY"
  },
  {
    label: "Haïti",
    countryCode: "HT"
  },
  {
    label: "Heard en McDonaldeilanden",
    countryCode: "HM"
  },
  {
    label: "Honduras",
    countryCode: "HN"
  },
  {
    label: "Hongarije",
    countryCode: "HU"
  },
  {
    label: "Hongkong",
    countryCode: "HK"
  },
  {
    label: "Ierland",
    countryCode: "IE"
  },
  {
    label: "IJsland",
    countryCode: "IS"
  },
  {
    label: "India",
    countryCode: "IN"
  },
  {
    label: "Indonesië",
    countryCode: "ID"
  },
  {
    label: "Irak",
    countryCode: "IQ"
  },
  {
    label: "Iran",
    countryCode: "IR"
  },
  {
    label: "Israël",
    countryCode: "IL"
  },
  {
    label: "Italië",
    countryCode: "IT"
  },
  {
    label: "Ivoorkust",
    countryCode: "CI"
  },
  {
    label: "Jamaica",
    countryCode: "JM"
  },
  {
    label: "Japan",
    countryCode: "JP"
  },
  {
    label: "Jemen",
    countryCode: "YE"
  },
  {
    label: "Jersey",
    countryCode: "JE"
  },
  {
    label: "Jordanië",
    countryCode: "JO"
  },
  {
    label: "Kaaimaneilanden",
    countryCode: "KY"
  },
  {
    label: "Kaapverdië",
    countryCode: "CV"
  },
  {
    label: "Kameroen",
    countryCode: "CM"
  },
  {
    label: "Kazachstan",
    countryCode: "KZ"
  },
  {
    label: "Kenia",
    countryCode: "KE"
  },
  {
    label: "Kirgizië",
    countryCode: "KG"
  },
  {
    label: "Kiribati",
    countryCode: "KI"
  },
  {
    label: "Kleine afgelegen eilanden van de Verenigde Staten",
    countryCode: "UM"
  },
  {
    label: "Koeweit",
    countryCode: "KW"
  },
  {
    label: "Kosovo",
    countryCode: "XK"
  },
  {
    label: "Kroatië",
    countryCode: "HR"
  },
  {
    label: "Laos",
    countryCode: "LA"
  },
  {
    label: "Lesotho",
    countryCode: "LS"
  },
  {
    label: "Letland",
    countryCode: "LV"
  },
  {
    label: "Libanon",
    countryCode: "LB"
  },
  {
    label: "Liberia",
    countryCode: "LR"
  },
  {
    label: "Libië",
    countryCode: "LY"
  },
  {
    label: "Liechtenstein",
    countryCode: "LI"
  },
  {
    label: "Litouwen",
    countryCode: "LT"
  },
  {
    label: "Luxemburg",
    countryCode: "LU"
  },
  {
    label: "Macau",
    countryCode: "MO"
  },
  {
    label: "Macedonië",
    countryCode: "MK"
  },
  {
    label: "Madagaskar",
    countryCode: "MG"
  },
  {
    label: "Malawi",
    countryCode: "MW"
  },
  {
    label: "Maldiven",
    countryCode: "MV"
  },
  {
    label: "Maleisië",
    countryCode: "MY"
  },
  {
    label: "Mali",
    countryCode: "ML"
  },
  {
    label: "Malta",
    countryCode: "MT"
  },
  {
    label: "Man",
    countryCode: "IM"
  },
  {
    label: "Marokko",
    countryCode: "MA"
  },
  {
    label: "Marshalleilanden",
    countryCode: "MH"
  },
  {
    label: "Martinique",
    countryCode: "MQ"
  },
  {
    label: "Mauritanië",
    countryCode: "MR"
  },
  {
    label: "Mauritius",
    countryCode: "MU"
  },
  {
    label: "Mayotte",
    countryCode: "YT"
  },
  {
    label: "Mexico",
    countryCode: "MX"
  },
  {
    label: "Micronesia",
    countryCode: "FM"
  },
  {
    label: "Moldavië",
    countryCode: "MD"
  },
  {
    label: "Monaco",
    countryCode: "MC"
  },
  {
    label: "Mongolië",
    countryCode: "MN"
  },
  {
    label: "Montenegro",
    countryCode: "ME"
  },
  {
    label: "Montserrat",
    countryCode: "MS"
  },
  {
    label: "Mozambique",
    countryCode: "MZ"
  },
  {
    label: "Myanmar",
    countryCode: "MM"
  },
  {
    label: "Namibië",
    countryCode: "NA"
  },
  {
    label: "Nauru",
    countryCode: "NR"
  },
  {
    label: "Nederland",
    countryCode: "NL"
  },
  {
    label: "Nepal",
    countryCode: "NP"
  },
  {
    label: "Nicaragua",
    countryCode: "NI"
  },
  {
    label: "Nieuw-Caledonië",
    countryCode: "NC"
  },
  {
    label: "Nieuw-Zeeland",
    countryCode: "NZ"
  },
  {
    label: "Niger",
    countryCode: "NE"
  },
  {
    label: "Nigeria",
    countryCode: "NG"
  },
  {
    label: "Niue",
    countryCode: "NU"
  },
  {
    label: "Noordelijke Marianen",
    countryCode: "MP"
  },
  {
    label: "Noord-Korea",
    countryCode: "KP"
  },
  {
    label: "Noorwegen",
    countryCode: "NO"
  },
  {
    label: "Norfolk",
    countryCode: "NF"
  },
  {
    label: "Oeganda",
    countryCode: "UG"
  },
  {
    label: "Oekraïne",
    countryCode: "UA"
  },
  {
    label: "Oezbekistan",
    countryCode: "UZ"
  },
  {
    label: "Oman",
    countryCode: "OM"
  },
  {
    label: "Oostenrijk",
    countryCode: "AT"
  },
  {
    label: "Oost-Timor",
    countryCode: "TL"
  },
  {
    label: "Pakistan",
    countryCode: "PK"
  },
  {
    label: "Palau",
    countryCode: "PW"
  },
  {
    label: "Palestina",
    countryCode: "PS"
  },
  {
    label: "Panama",
    countryCode: "PA"
  },
  {
    label: "Papua-Nieuw-Guinea",
    countryCode: "PG"
  },
  {
    label: "Paraguay",
    countryCode: "PY"
  },
  {
    label: "Peru",
    countryCode: "PE"
  },
  {
    label: "Pitcairneilanden",
    countryCode: "PN"
  },
  {
    label: "Polen",
    countryCode: "PL"
  },
  {
    label: "Portugal",
    countryCode: "PT"
  },
  {
    label: "Puerto Rico",
    countryCode: "PR"
  },
  {
    label: "Qatar",
    countryCode: "QA"
  },
  {
    label: "Réunion",
    countryCode: "RE"
  },
  {
    label: "Roemenië",
    countryCode: "RO"
  },
  {
    label: "Rusland",
    countryCode: "RU"
  },
  {
    label: "Rwanda",
    countryCode: "RW"
  },
  {
    label: "Saint-Barthélemy",
    countryCode: "BL"
  },
  {
    label: "Saint Kitts en Nevis",
    countryCode: "KN"
  },
  {
    label: "Saint Lucia",
    countryCode: "LC"
  },
  {
    label: "Saint-Pierre en Miquelon",
    countryCode: "PM"
  },
  {
    label: "Saint Vincent en de Grenadines",
    countryCode: "VC"
  },
  {
    label: "Salomonseilanden",
    countryCode: "SB"
  },
  {
    label: "Samoa",
    countryCode: "WS"
  },
  {
    label: "San Marino",
    countryCode: "SM"
  },
  {
    label: "Saoedi-Arabië",
    countryCode: "SA"
  },
  {
    label: "Sao Tomé en Principe",
    countryCode: "ST"
  },
  {
    label: "Senegal",
    countryCode: "SN"
  },
  {
    label: "Servië",
    countryCode: "RS"
  },
  {
    label: "Seychellen",
    countryCode: "SC"
  },
  {
    label: "Sierra Leone",
    countryCode: "SL"
  },
  {
    label: "Singapore",
    countryCode: "SG"
  },
  {
    label: "Sint-Helena, Ascension en Tristan da Cunha",
    countryCode: "SH"
  },
  {
    label: "Sint-Maarten",
    countryCode: "MF"
  },
  {
    label: "Sint Maarten",
    countryCode: "SX"
  },
  {
    label: "Slovenië",
    countryCode: "SI"
  },
  {
    label: "Slowakije",
    countryCode: "SK"
  },
  {
    label: "Soedan",
    countryCode: "SD"
  },
  {
    label: "Somalië",
    countryCode: "SO"
  },
  {
    label: "Spanje",
    countryCode: "ES"
  },
  {
    label: "Spitsbergen en Jan Mayen",
    countryCode: "SJ"
  },
  {
    label: "Sri Lanka",
    countryCode: "LK"
  },
  {
    label: "Suriname",
    countryCode: "SR"
  },
  {
    label: "Swaziland",
    countryCode: "SZ"
  },
  {
    label: "Syrië",
    countryCode: "SY"
  },
  {
    label: "Tadzjikistan",
    countryCode: "TJ"
  },
  {
    label: "Taiwan",
    countryCode: "TW"
  },
  {
    label: "Tanzania",
    countryCode: "TZ"
  },
  {
    label: "Thailand",
    countryCode: "TH"
  },
  {
    label: "Togo",
    countryCode: "TG"
  },
  {
    label: "Tokelau",
    countryCode: "TK"
  },
  {
    label: "Tonga",
    countryCode: "TO"
  },
  {
    label: "Trinidad en Tobago",
    countryCode: "TT"
  },
  {
    label: "Tsjaad",
    countryCode: "TD"
  },
  {
    label: "Tsjechië",
    countryCode: "CZ"
  },
  {
    label: "Tunesië",
    countryCode: "TN"
  },
  {
    label: "Turkije",
    countryCode: "TR"
  },
  {
    label: "Turkmenistan",
    countryCode: "TM"
  },
  {
    label: "Turks- en Caicoseilanden",
    countryCode: "TC"
  },
  {
    label: "Tuvalu",
    countryCode: "TV"
  },
  {
    label: "Uruguay",
    countryCode: "UY"
  },
  {
    label: "Vanuatu",
    countryCode: "VU"
  },
  {
    label: "Vaticaanstad",
    countryCode: "VA"
  },
  {
    label: "Venezuela",
    countryCode: "VE"
  },
  {
    label: "Verenigde Arabische Emiraten",
    countryCode: "AE"
  },
  {
    label: "Verenigde Staten",
    countryCode: "US"
  },
  {
    label: "Verenigd Koninkrijk",
    countryCode: "GB"
  },
  {
    label: "Vietnam",
    countryCode: "VN"
  },
  {
    label: "Wallis en Futuna",
    countryCode: "WF"
  },
  {
    label: "Westelijke Sahara",
    countryCode: "EH"
  },
  {
    label: "Wit-Rusland",
    countryCode: "BY"
  },
  {
    label: "Zambia",
    countryCode: "ZM"
  },
  {
    label: "Zimbabwe",
    countryCode: "ZW"
  },
  {
    label: "Zuid-Afrika",
    countryCode: "ZA"
  },
  {
    label: "Zuid-Georgia en de Zuidelijke Sandwicheilanden",
    countryCode: "GS"
  },
  {
    label: "Zuid-Korea",
    countryCode: "KR"
  },
  {
    label: "Zuid-Soedan",
    countryCode: "SS"
  },
  {
    label: "Zweden",
    countryCode: "SE"
  },
  {
    label: "Zwitserland",
    countryCode: "CH"
  }
];

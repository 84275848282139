import React from "react";

import image from "../../assets/Banner.jpg";

const containerStyle = {
  minHeight: "30rem",
  position: "relative",
  overflow: "hidden"
};

const imageStyle = {
  mbackgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "100%",
  backgroundImage: "url(" + image + ")",
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  position: "absolute",
  top: 0,
  left: 0
};

const overlayStyle = {
  backgroundImage:
    "linear-gradient(to bottom, rgba(0, 0, 0, 0.568), rgb(0, 0, 0))",
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1
};

export default ({ title, buttonText, history, redirectTo }) => {
  return (
    <React.Fragment>
      <div style={containerStyle}>
        <div style={overlayStyle} />
        <div style={imageStyle} />
        <div
          className="content offset-1 d-flex flex-column"
          style={{ position: "absolute", zIndex: 1, top: "10rem" }}
        >
          <h1 style={{ color: "white" }} className="mb-5">
            {title}
          </h1>
          <div
            onClick={() => history.push(redirectTo)}
            className="gold mb-5 pl-1 pr-1 align-self-start"
          >
            <p className="m-0 gold-button">{buttonText}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

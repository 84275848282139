import React, { useState } from "react";
import Storage from "@aws-amplify/storage";
import {
  fetchSingleCarAction,
  addCarAction,
  updateCarAction,
  resetCar,
  setSpinnerAction,
  setModalAction,
} from "../../context/Actions";
import { Store } from "../../context/Store";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import "./CarDetails.css";
import CarDetailsBanner from "./CarDetailsBanner";
import Modal from "../core/Modal";

import { useDrop } from "react-dnd";

import update from "immutability-helper";
import ItemTypes from "./ItemTypes";
import CarInfoForm from "./CarInfoForm";
import CarExtraForm from "./CarExtraForm";
import CarOptionsForm from "./CarOptionsForm";
import DropImages from "../shared/DropImages";
import DropFiles from "./DropFiles";
import { uploadImage, uploadFile } from "../../services/uploadFiles";

export default (props) => {
  const [car, setCar] = useState({
    brand: "",
    series: "",
    brandDropdown: "AUDI",

    infoDescription: "",

    infoBuildYear: "",
    infoMileage: "",
    infoPower: "",
    infoBodyShape: "",
    infoDrive: "",
    infoTransmission: "",
    infoColor: "",
    infoInterior: "",

    infoPriceNew: "",
    infoPrice: "",
    infoAdvantage: "",
    infoBTWRegime: "",

    infoEntryIntoServiceDate: "",
    infoFuel: "",
    infoCO2: "",
    infoEuronorm: "",
    infoFiscalHP: "",
    infoEngineCapacity: "",
    infoCylinders: "",

    isVisible: true,
    isSold: false,
  });

  const [verkoopDocumenten, setVerkoopDocumenten] = useState([]);
  const [verkoopDocumentenPreview, setVerkoopDocumentenPreview] = useState([]);
  const [aankoopDocumenten, setAankoopDocumenten] = useState([]);
  const [aankoopDocumentenPreview, setAankoopDocumentenPreview] = useState([]);
  const [andereDocumenten, setAndereDocumenten] = useState([]);
  const [andereDocumentenPreview, setAndereDocumentenPreview] = useState([]);
  const [validated, setValidated] = useState(false);
  const [trumps, setTrumps] = useState([]);
  const [options, setOptions] = useState([]);
  const [pictures, setPictures] = useState([]);

  const moveTrump = (id, atIndex) => {
    const { card, index } = findTrump(id);
    setTrumps(
      update(trumps, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
      })
    );
  };

  const findTrump = (id) => {
    const card = trumps.filter((c) => `${c.id}` === id)[0];
    return {
      card,
      index: trumps.indexOf(card),
    };
  };

  const moveOption = (id, atIndex) => {
    const { card, index } = findOption(id);
    setOptions(
      update(options, {
        $splice: [
          [index, 1],
          [atIndex, 0, card],
        ],
      })
    );
  };

  const findOption = (id) => {
    const card = options.filter((c) => `${c.id}` === id)[0];
    return {
      card,
      index: options.indexOf(card),
    };
  };

  const [, drop] = useDrop({ accept: ItemTypes.TRUMP });

  const blankDynamicInput = { id: 0, value: "", bold: false };

  const addTrump = () => {
    trumps.length > 0 &&
      (blankDynamicInput.id =
        Math.max.apply(
          Math,
          trumps.map(function (o) {
            return o.id;
          })
        ) + 1);
    setTrumps([...trumps, { ...blankDynamicInput }]);
  };

  const deleteTrump = (i) => {
    setTrumps(trumps.slice(0, i).concat(trumps.slice(i + 1, trumps.length)));
  };

  const handleTrumpChange = (e) => {
    const updatedTrumps = [...trumps];
    updatedTrumps[e.target.dataset.idx]["value"] = e.target.value;
    setTrumps(updatedTrumps);
  };

  const addOption = () => {
    options.length > 0 &&
      (blankDynamicInput.id =
        Math.max.apply(
          Math,
          options.map(function (o) {
            return o.id;
          })
        ) + 1);
    setOptions([...options, { ...blankDynamicInput }]);
  };

  const deleteOption = (i) => {
    setOptions(
      options.slice(0, i).concat(options.slice(i + 1, options.length))
    );
  };

  const handleOptionChange = (e) => {
    const updatedOptions = [...options];
    if (e.target.type === "checkbox") {
      updatedOptions[e.target.dataset.idx]["bold"] = e.target.checked;
    } else {
      updatedOptions[e.target.dataset.idx]["value"] = e.target.value;
    }
    setOptions(updatedOptions);
  };

  const { id } = props.match.params;
  const { state, dispatch } = React.useContext(Store);

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setCar((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    Modal({
      handleAction: () => submit(),
      action:
        "Bent u zeker dat u de wagen wilt " + (id ? "updaten?" : "aanmaken?"),
    });
  };

  const submit = () => {
    setSpinnerAction(true, dispatch);

    const transFormedCar = {
      ...car,
      trumps: trumps.map((trump) => trump.value),
      options: options.map((option) => ({
        value: option.value,
        bold: option.bold,
      })),
    };

    setValidated(true);

    let promises = [];
    let count = 1;
    let imagePaths = [];

    if (id) {
      pictures.forEach((picture) => {
        if (picture instanceof File) {
          promises.push(
            uploadImage(
              picture,
              "carImages",
              (car.brand + "-" + car.series + "-" + count).replace(/\s+/g, "-")
            )
          );
          count++;
        } else {
          imagePaths.push(picture.imagePath);
        }
      });
    } else {
      pictures.forEach((picture) => {
        promises.push(
          uploadImage(
            picture,
            "carImages",
            (car.brand + "-" + car.series + "-" + count).replace(/\s+/g, "-")
          )
        );
        count++;
      });
    }

    Promise.all(promises)
      .then((results) => {
        results.map((result) => imagePaths.push(result));
        transFormedCar.imagePaths = imagePaths;
        if (id) {
          updateCarAction(transFormedCar, dispatch, props.history);
        } else {
          addCarAction(transFormedCar, dispatch, props.history);
        }
      })
      .catch((error) => {
        console.log(error);
        setSpinnerAction(false, dispatch);
      });
  };

  const handleUpload = () => {
    setSpinnerAction(true, dispatch);
    let promises = [];

    verkoopDocumenten.forEach((picture) => {
      promises.push(uploadFile(picture, id + "/verkoopDocumenten"));
    });
    aankoopDocumenten.forEach((picture) => {
      promises.push(uploadFile(picture, id + "/aankoopDocumenten"));
    });
    andereDocumenten.forEach((picture) => {
      promises.push(uploadFile(picture, id + "/andereDocumenten"));
    });

    Promise.all(promises)
      .then(setSpinnerAction(false, dispatch))
      .catch(setSpinnerAction(false, dispatch));
  };

  React.useEffect(() => {
    if (id) {
      fetchSingleCarAction(id, dispatch);
    }
    return () => resetCar(dispatch);
  }, []);

  React.useEffect(() => {
    setSpinnerAction(true, dispatch);
    if (id && state.car.trumps) {
      setCar(state.car);
      setTrumps(
        state.car.trumps.map((c, i = 0) => {
          return { id: i++, value: c };
        })
      );
      setOptions(
        state.car.options.map((trump, i = 0) => {
          return { id: i++, value: trump.value, bold: trump.bold };
        })
      );

      getImages(state.car.imagePaths);

      Storage.list(id, { level: "private" })
        .then((result) => {
          let verkoopDocumentenFromResult = [];
          let aankoopDocumentenFromResult = [];
          let andereDocumentenFromResult = [];

          result.map((x) => {
            if (x.key.indexOf("verkoopDocumenten") !== -1) {
              verkoopDocumentenFromResult.push({
                key: x.key.substring(x.key.lastIndexOf("/") + 1),
                size: x.size,
              });
            } else if (x.key.indexOf("aankoopDocumenten") !== -1) {
              aankoopDocumentenFromResult.push({
                key: x.key.substring(x.key.lastIndexOf("/") + 1),
                size: x.size,
              });
            } else {
              andereDocumentenFromResult.push({
                key: x.key.substring(x.key.lastIndexOf("/") + 1),
                size: x.size,
              });
            }
          });

          setVerkoopDocumentenPreview(verkoopDocumentenFromResult);
          setAankoopDocumentenPreview(aankoopDocumentenFromResult);
          setAndereDocumentenPreview(andereDocumentenFromResult);
        })
        .catch(setSpinnerAction(false, dispatch));
    }
    setSpinnerAction(false, dispatch);
  }, [state.car]);

  const getImages = async (imagePaths) => {
    let images = [];
    imagePaths.map(async (imagePath) => {
      images.push({
        preview:
          "https://d1nlbhl3drwsqr.cloudfront.net/600x400/filters:upscale()/public/" +
          imagePath,
        imagePath,
      });
    });
    setPictures(images);
  };

  const removeFile = (file, type) => {
    setSpinnerAction(true, dispatch);
    Storage.remove(id + "/" + type + "/" + file.key, { level: "private" })
      .then((result) => {
        setSpinnerAction(false, dispatch);
      })
      .catch(setSpinnerAction(false, dispatch));
  };

  return (
    <React.Fragment>
      <CarDetailsBanner id={id} />
      <Col md={{ span: 10, offset: 1 }}>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mt-5">
            <Col md={{ span: 4 }}>
              <Form.Group controlId="formBrand">
                <Form.Label>Merk</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="bv. Mercedes"
                  name="brand"
                  value={car.brand}
                  onChange={(event) => handleChange(event)}
                />
              </Form.Group>
            </Col>
            <Col md={{ span: 4 }}>
              <Form.Group controlId="formSeries">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="bv. CLA200 coupé"
                  name="series"
                  value={car.series}
                  onChange={(event) => handleChange(event)}
                />
              </Form.Group>
            </Col>
            <Col md={{ span: 4 }}>
              <Form.Group controlId="formBrandDropdown">
                <Form.Label>Onderverdeling merk</Form.Label>
                <Form.Control
                  required
                  as="select"
                  name="brandDropdown"
                  value={car.brandDropdown}
                  onChange={(event) => handleChange(event)}
                >
                  <option>AUDI</option>
                  <option>BMW</option>
                  <option>MERCEDES</option>
                  <option>VOLKSWAGEN</option>
                  <option value="OTHER">ANDERE</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-5 ">
            <Form.Group controlId="formIsVisible">
              <Form.Check
                type="checkbox"
                label="Zichtbaar"
                name="isVisible"
                checked={car.isVisible}
                onChange={(event) => handleChange(event)}
              />
            </Form.Group>
          </Row>
          <Row className="mt-2 ">
            <Form.Group controlId="formIsSold">
              <Form.Check
                type="checkbox"
                label="Verkocht"
                name="isSold"
                checked={car.isSold}
                onChange={(event) => handleChange(event)}
              />
            </Form.Group>
          </Row>
          <Row className="mt-5">
            <Col xl={{ span: 4 }} lg={{ span: 6 }}>
              <div className="divTitle">INFO</div>
              <CarInfoForm handleChange={handleChange} car={car} />
            </Col>
            <Col xl={{ span: 4 }} lg={{ span: 6 }}>
              <div className="divTitle">EXTRA</div>
              <CarExtraForm
                car={car}
                handleChange={handleChange}
                moveTrump={moveTrump}
                findTrump={findTrump}
                deleteTrump={deleteTrump}
                trumps={trumps}
                handleTrumpChange={handleTrumpChange}
                addTrump={addTrump}
                drop={drop}
              />
            </Col>
            <Col xl={{ span: 4 }} lg={{ span: 6 }}>
              <div className="divTitle">OPTIES</div>
              <CarOptionsForm
                moveOption={moveOption}
                findOption={findOption}
                deleteOption={deleteOption}
                options={options}
                handleOptionChange={handleOptionChange}
                addOption={addOption}
                drop={drop}
              />
            </Col>
          </Row>
          <Row>
            <h2 class="mt-5 mb-5 d-block">AFBEELDINGEN</h2>
            <DropImages files={pictures} setFiles={setPictures}></DropImages>
          </Row>
          <Button variant="dark" type="submit" className="mt-3 mb-3">
            {" "}
            <FontAwesomeIcon icon={faCheck} />{" "}
            {id ? "Update wagen" : "Voeg wagen toe"}
          </Button>
        </Form>
        {id && (
          <Row>
            <Col xl={{ span: 4 }} lg={{ span: 6 }}>
              <div className="divTitle mb-3">Aankoopdocumenten</div>
              <DropFiles
                type={"aankoopDocumenten"}
                files={aankoopDocumenten}
                setFiles={setAankoopDocumenten}
                removeFile={removeFile}
                previewFiles={aankoopDocumentenPreview}
                setPreviewFiles={setAankoopDocumentenPreview}
                id={id}
              ></DropFiles>
            </Col>
            <Col xl={{ span: 4 }} lg={{ span: 6 }}>
              <div className="divTitle mb-3">Verkoopdocumenten</div>
              <DropFiles
                type={"verkoopDocumenten"}
                files={verkoopDocumenten}
                setFiles={setVerkoopDocumenten}
                removeFile={removeFile}
                previewFiles={verkoopDocumentenPreview}
                setPreviewFiles={setVerkoopDocumentenPreview}
                id={id}
              ></DropFiles>
            </Col>
            <Col xl={{ span: 4 }} lg={{ span: 6 }}>
              <div className="divTitle mb-3">Andere</div>
              <DropFiles
                type={"andereDocumenten"}
                files={andereDocumenten}
                setFiles={setAndereDocumenten}
                removeFile={removeFile}
                previewFiles={andereDocumentenPreview}
                setPreviewFiles={setAndereDocumentenPreview}
                id={id}
              ></DropFiles>
            </Col>
            <Button variant="dark" onClick={handleUpload} className="mt-3 mb-3">
              {" "}
              <FontAwesomeIcon icon={faCheck} /> Upload nieuwe bestanden.
            </Button>
          </Row>
        )}
      </Col>
    </React.Fragment>
  );
};

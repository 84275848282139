import React from "react";

import Banner from "../shared/Banner";

import { Store } from "../../context/Store";
import ImportedList from "./ImportedList";

export default props => {
  const { state, dispatch } = React.useContext(Store);
  const { isOnline, history } = props;

  return (
    <React.Fragment>
      <Banner
        dispatch={dispatch}
        title={
          "Overzicht van alle geïmporteerde wagens die " +
          (isOnline ? "online" : "offline") +
          " staan"
        }
        buttonText={"Voeg nieuwe geïmporteerde wagen toe"}
        redirectTo={"import-create"}
        history={history}
      />
      <ImportedList state={state} dispatch={dispatch} isOnline={isOnline} />
    </React.Fragment>
  );
};

import React from "react";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import TrumpInputs from "./TrumpInputs";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default ({
  car,
  handleChange,
  moveTrump,
  findTrump,
  deleteTrump,
  trumps,
  handleTrumpChange,
  addTrump,
  drop
}) => {
  return (
    <React.Fragment>
      <h2 className="mt-3">BEREKENINGEN</h2>
      <Form.Group controlId="formExtraBIV" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">BIV</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            placeholder="bv. €674"
            name="BIV"
            value={car.BIV}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />
      <Form.Group controlId="formExtraRoadTax" className="row">
        <Col sm={4}>
          <Form.Label className="col-form-label">Verkeersbelasting</Form.Label>
        </Col>
        <Col sm={8}>
          <Form.Control
            required
            type="text"
            placeholder="bv. €483"
            name="roadTax"
            value={car.roadTax}
            onChange={event => handleChange(event)}
          />
        </Col>
      </Form.Group>
      <hr />

      <div className="d-flex align-content-center">
        <Button variant="dark" onClick={addTrump} className="mt-3 mb-3">
          {" "}
          <FontAwesomeIcon icon={faPlus} /> Voeg een troef toe
        </Button>
        <span className="ml-3 my-auto">Versleep om van plaats te wisselen</span>
      </div>
      <div ref={drop}>
        {trumps.map((val, idx) => (
          <React.Fragment key={trumps[idx].id}>
            <TrumpInputs
              idx={idx}
              id={`${trumps[idx].id}`}
              trumps={trumps}
              handleTrumpChange={handleTrumpChange}
              moveTrump={moveTrump}
              findTrump={findTrump}
              deleteTrump={deleteTrump}
            />
          </React.Fragment>
        ))}
      </div>
    </React.Fragment>
  );
};

import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Button from "react-bootstrap/Button";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16
};

const thumb = {
  position: "relative",
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box"
};

const removeIcon = {
  color: "white",
  borderRadius: "0.2rem",
  padding: "0.3rem",
  position: "absolute",
  right: "-5px",
  top: "-5px",
  cursor: "pointer"
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden"
};

const img = {
  display: "block",
  width: "auto",
  height: "100%"
};

export default function Previews(props) {
  const { files, setFiles } = props;
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: acceptedFiles => {
      console.log(files);
      setFiles([
        ...files,
        ...acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      ]);
    }
  });

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <div
          onClick={() => removeFile(file)}
          style={removeIcon}
          className="bg-danger"
        >
          <FontAwesomeIcon icon={faMinus} />
        </div>
        <img src={file.preview} style={img} />
      </div>
    </div>
  ));

  const removeFile = file => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const removeAll = () => {
    setFiles([]);
  };

  useEffect(
    () => () => {
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <p>
          Drag 'n' drop hier uw afbeeldingen, of klik om afbeeldingen te
          selecteren.
        </p>
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
      {files.length > 0 && (
        <Button
          variant="danger"
          type="submit"
          onClick={removeAll}
          className="mt-3 mb-3"
        >
          Verwijder alle afbeeldingen
        </Button>
      )}
    </>
  );
}
